import Link from 'next/link';
import NextImage from 'next/image';
import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import {
  useIsDesktop,
  handleArrowKeyFocus,
  handleRedirectClick,
} from '@helpers/utils';
import FacebookIcon from '@footerImages/facebook-rounded.svg';
import InstagramIcon from '@footerImages/instagram-rounded.svg';
import styles from './Footer.module.scss';
import WonderfulPistachiosLogo from '@static/WonderfulPistachiosLogo';
import Popup from '@modules/Popup/Popup';
import { PopupProps } from '@modules/Popup/Popup.types';

export default function Footer() {
  const isDesktop = useIsDesktop();
  const { t } = useTranslation('common');

  const copyrightText = (
    <p key="copyright" className={styles.copyrightLink}>
      {`© ${new Date().getFullYear()} ${t(
        'Wonderful Pistachios & Almonds LLC.'
      )}`}
    </p>
  );

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [redirectLink, setRedirectLink] = useState('');

  const popupProps = {
    label: t('We’ll miss you!'),
    description: t(
      `You are now leaving the {{hostname}} site for Europe, Middle East, and Africa.`,
      { hostname: 'www.wonderfulpistachios.com' }
    ),
    isPopupOpen: isPopupOpen,
    setIsPopupOpen: setIsPopupOpen,
    redirectLink: redirectLink,
    setRedirectLink: setRedirectLink,
    cancelBtnText: t('Go back'),
    acceptBtnText: t('Continue'),
  };

  return (
    <>
      {isPopupOpen && <Popup {...popupProps} />}
      <div className={styles.getCrackinContainer}>
        <div className={styles.openingShellImageContainer}>
          <NextImage
            src="/images/footer/emerging-from-shell.png"
            layout="fill"
            objectFit="contain"
            alt=""
          />
        </div>
        <strong className={styles.getCrackinText}>Get Crackin’&lrm;</strong>
      </div>
      <footer className={styles.footer}>
        <div className="container">
          <div className={styles.mainContent}>
            <Link href="/">
              <a
                className={styles.ownLogo}
                aria-label="Wonderful Pistachios Logo"
              >
                <WonderfulPistachiosLogo />
              </a>
            </Link>
            <SiteAndSocialLinks key="site-links" />
          </div>
          <div className={styles.brands}>
            <nav aria-label="Wonderful Company brands">
              <ul className={styles.brandsRow} data-testid={'footer-brands'}>
                <BrandLink
                  href="https://www.wonderful.com/"
                  img="/images/footer/logo_twc.svg"
                  alt="The Wonderful Company"
                  height={27}
                  width={271}
                  popupProps={popupProps}
                />
                <BrandLink
                  href="https://www.wonderfulpistachios.com/"
                  img="/images/footer/logo_pistachios-grey.svg"
                  alt="Wonderful Pistachios"
                  height={32}
                  width={112}
                  popupProps={popupProps}
                />
                <BrandLink
                  href="https://www.pomwonderful.com/"
                  img="/images/footer/logo_pom.svg"
                  alt="Pom Wonderful"
                  height={34}
                  width={73}
                  popupProps={popupProps}
                />
              </ul>
            </nav>
          </div>
          <div className={styles.bottomItems} data-testid={'footer-bottom'}>
            {isDesktop
              ? [copyrightText, <LegalLinks key="legal-links" />]
              : [<LegalLinks key="legal-links" />, copyrightText]}
          </div>
        </div>
      </footer>
    </>
  );
}

const getFirstProductsLinks = (t: any) => [
  { text: t('all'), link: '/products' },
  { text: t('in-shell'), link: `/products?filter=${t('in-shell')}` },
  { text: t('no shells'), link: `/products?filter=${t('no shells')}` },
];

const getSecondProductsLinks = (t: any) => [
  { text: t('almonds'), link: `/products?filter=${t('almonds')}` },
  { text: t('flavours'), link: `/products?filter=${t('flavours')}` },
  { text: t('where to buy'), link: '/where-to-buy' },
];

const getAboutUsLinks = (t: any) => [
  { text: t('contact us'), link: '/contact' },
  { text: t('FAQs'), link: '/faq' },
  { text: t('sitemap'), link: '/sitemap' },
];

const SiteAndSocialLinks = React.memo(() => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const renderSiteLink = (
    link: { text: string; link: string },
    index: number
  ) => (
    <li key={index}>
      <Link href={link.link}>
        <a>{link.text}</a>
      </Link>
    </li>
  );

  const handleHorizontalArrows = handleArrowKeyFocus(
    'horizontal',
    `.${styles.siteLinksColumn}`
  );
  const handleVerticalArrows = handleArrowKeyFocus('vertical');

  return (
    <nav
      className={styles.siteLinks}
      onKeyDown={e => {
        handleHorizontalArrows(e);
        handleVerticalArrows(e);
      }}
      aria-label="footer links"
      data-testid={'footer-links'}
    >
      <div className={styles.siteLinksColumn} data-testid={'product-links'}>
        <strong className={styles.siteLinksHeader}>{t('products')}</strong>
        <div className={styles.siteLinksColumnWrap}>
          <ul>{getFirstProductsLinks(t).map(renderSiteLink)}</ul>
          <ul>{getSecondProductsLinks(t).map(renderSiteLink)}</ul>
        </div>
      </div>
      <div className={styles.siteLinksColumn} data-testid={'about-links'}>
        <strong className={styles.siteLinksHeader}>{t('about us')}</strong>
        <ul>{getAboutUsLinks(t).map(renderSiteLink)}</ul>
      </div>
      {locale !== 'de' && locale !== 'nl' && (
        <div className={styles.siteLinksColumn} data-testid={'footer-social'}>
          <strong className={styles.siteLinksHeader}>{t('follow us')}</strong>
          <SocialLinks />
        </div>
      )}
    </nav>
  );
});
// fixes react.memo display name eslint issue
SiteAndSocialLinks.displayName = 'SiteAndSocialLinks';

interface BrandLinkProps {
  href: string;
  img: string;
  alt: string;
  height: number;
  width: number;
  popupProps?: PopupProps | undefined;
}

const BrandLink = React.memo((props: BrandLinkProps) => {
  const { popupProps } = props;

  return (
    <li className={styles.brandLink}>
      <a
        href={props.href}
        target={popupProps ? '_blank' : '_self'}
        rel="noopener noreferrer"
        onClick={
          popupProps
            ? event =>
                handleRedirectClick(
                  props?.href,
                  popupProps?.isPopupOpen,
                  popupProps?.setIsPopupOpen,
                  popupProps?.setRedirectLink,
                  event
                )
            : undefined
        }
      >
        <NextImage
          loading="lazy"
          src={props.img}
          alt={`${props.alt} (opens in new tab)`}
          height={props.height}
          width={props.width}
        />
      </a>
    </li>
  );
});

BrandLink.displayName = 'BrandLink';

const LegalLinks = React.memo(() => {
  const { t } = useTranslation('common');
  return (
    <ul className={styles.legalLinks} aria-label="legal">
      <li className={styles.legalLink}>
        <Link href="/terms-of-use">
          <a>{t('terms of use')}</a>
        </Link>
      </li>
      <li> | </li>
      <li className={styles.legalLink}>
        <Link href="/privacy-policy">
          <a>{t('privacy policy')}</a>
        </Link>
      </li>
      <li> | </li>
      <li className={styles.legalLink}>
        <a
          onClick={() => {
            // @ts-ignore - OneTrust function name
            Optanon.ToggleInfoDisplay();
          }}
        >
          {t('Do Not Sell or Share My Personal Information')}
        </a>
      </li>
    </ul>
  );
});

LegalLinks.displayName = 'LegalLinks';

const SocialLinks = () => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  return (
    <ul className={styles.socialIcons}>
      <li>
        <a
          href={t('https://www.facebook.com/wonderfulpistachiosandalmonds')}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>Facebook</span>
          <FacebookIcon role="img" title="Follow us on Facebook" />
        </a>
      </li>
      {locale !== 'en-us' && (
        <li>
          <a
            href={t('https://www.instagram.com/wonderfulpistachios/')}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>Instagram</span>
            <InstagramIcon role="img" title="Follow us on Instagram" />
          </a>
        </li>
      )}
    </ul>
  );
};
